.table {
  th,
  td {
    padding: 0.75rem;
    vertical-align: top;
  }

  thead {
    tr {
      th {
        background-color: unset;
      }
    }
  }

  &__control {
    @include font-size(small);
    @include font-weight(light);
    margin: 10px 30px 10px 0;
    // color: color(indigo);
    cursor: pointer;
    transition: transform 0.3s;

    button {
      font-weight: inherit;
      font-size: inherit;
      // color: inherit;
    }

    &:hover {
      // color: initial;
      transform: translateY(-1px);
      -webkit-transform: translateY(-1px);
    }
  }
}

.teams,
.residents {
  .table {
    color: color(dark);

    th,
    td {
      background-color: color(white);
      padding-top: 15px;
      padding-bottom: 15px;
    }

    th {
      &:first-child {
        border-radius: 10px 0 0 10px;
      }

      &:last-child {
        border-radius: 0 10px 10px 0;
      }
    }

    &-header,
    &-content {
      @include font-size(regular);
      @include border-radius(7px);
    }

    &-header {
      text-transform: uppercase;
    }

    &-content {
      border-collapse: separate;

      tr:first-child td:first-child {
        border-top-left-radius: 10px;
      }

      tr:first-child td:last-child {
        border-top-right-radius: 10px;
      }

      tr:last-child td:first-child {
        border-bottom-left-radius: 10px;
      }

      tr:last-child td:last-child {
        border-bottom-right-radius: 10px;
      }
    }

    tbody {
      &:before {
        line-height: 1em;
        content: '.';
        color: transparent;
        display: block;
      }
    }

    .disabled {
      opacity: 0.5;
    }
  }
}

.square-tables {
  table {
    @include font-size(small);
    @include border-radius(10px);
    color: color(dark);
    border: 1px solid color-not-opaque(gray);
    text-transform: capitalize;
    // table-layout: fixed;
    // border-collapse: separate;

    thead {
      border-collapse: collapse;

      tr {
        background-color: color(lilac);
      }
    }

    tbody {
      tr {
        background-color: color(white);
        border-bottom: 1px solid color-not-opaque(gray);
      }
    }

    .disabled {
      opacity: 0.5;
    }
  }
}

.corner-tables {
  table {
    @include font-size(small);
    @include border-radius(10px);
    color: color(dark);
    // border: 1px solid color-not-opaque(gray);
    border-collapse: separate;

    tr:first-child th:first-child {
      border-top-left-radius: 6px;
    }

    tr:first-child th:last-child {
      border-top-right-radius: 6px;
    }

    tr:last-child td:first-child {
      border-bottom-left-radius: 6px;
    }

    tr:last-child td:last-child {
      border-bottom-right-radius: 6px;
    }

    thead {
      tr {
        background-color: $lilac;
      }
    }

    tbody {
      tr {
        background-color: color(white);
        border-bottom: 1px solid color-not-opaque(gray);
      }
    }
  }
}
