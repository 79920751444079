.filter {
  .form {
    label {
      @include font-size(x-small);
    }

    input,
    select {
      @include font-size(x-small);
      height: 25px;
    }

    // select {

    // }

    &-group {
      margin-bottom: 1px;
    }
    &-control {
      @include font-size(x-small);
      padding: 0.05rem 0.5rem !important;
    }

    button {
      margin-top: 10px;
    }
  }

  .react-datepicker {
    @include font-family(avenir);
    @include font-size(x-small);

    &-wrapper {
      width: 100%;

      input {
        @include border-radius(5px);
        width: 100%;
        padding: 0.05rem 0.5rem !important;
      }
    }

    &__header {
      &__dropdown {
        margin: 0.1rem 0;
      }
    }

    &__month {
      margin: 0.05rem;
    }

    &__day {
      width: 1.2rem;
      line-height: 1.2rem;
    }

    &__day-names {
      padding: 0.2rem 0;
    }

    &__day-name {
      width: 1.2rem;
      line-height: 1.2rem;
    }

    &__current-month {
      @include font-size(small);
      padding-bottom: 0.5rem;
    }
  }
}
