.notification {
    margin-top: 3px;

    &__header {
        color: color(indigo);
    }

    &-cursor {
        cursor: pointer;
    }

    &-active {
        color: color(red);
    }

    .notif-spot {
        color: color(enaro-grey);
    }

    .notif-badge {
        position: absolute;
        top: -5px;
        right: -5px;
        background: color(red);
        display: flex;
        align-items: center;
        justify-content: center;
        color: color(white);
        height: 12px;
        width: 12px;
        border-radius: 15px;
        font-size: 6px;
    }

    .active-list {

        .notif-spot {
            color: color(red);
        }
    }

    .not-read {
        color: color(red);
    }

    .popover {
        @include border-radius(0);
        @include box-shadow(1px, 1px, 5px, rgba(21, 21, 25, 0.155));
        border: 0px;
        min-width: 285px;
        max-width: 285px !important;
        padding-bottom: 40px;

        // .icon-blue-dot {
        //   color: color(red);
        // }

        // .icon-isread {
        //   color: color(enaro-grey);
        // }

        &-body {
            padding: 0;
        }
    }

    &__body {
        padding-bottom: 40px;

        .icon-blue-dot {
            color: color(red);
        }

        .icon-isread {
            color: color(enaro-grey);
        }

        &-height {
            min-height: 300px;
            max-height: 300px;
            overflow: scroll;
        }
    }

    .arrow {
        &:before {
            border-width: 0 0 0 0;
        }
    }

    &-title {
        margin: 0 0 0.125rem 0;
        padding-right: 1rem;

        button {
            text-align: left !important;
        }
    }

    &-list {
        @include font-size(small);
        background: color(main-bg);
        padding: 0 0 0;
    }

    &-item {
        @include font-family(avenir);
        color: color(enaro-grey);
        margin: 0.3125rem 0;
        padding: 0.625rem 0;
    }

    // &-details {
    //   margin: 0.625rem 0;
    // }

    &-status,
    &-option {
        padding: 0 0.625rem;
    }
}

.notifications {
    .read {
        opacity: 0.5;
    }
}
