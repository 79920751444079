.meter {
  &-card {
    @include border-radius(0);
    border: 0;
    @include box-shadow(0px, 0px, 20px, rgba(21, 21, 25, 0.1));

    &__header {
      background-color: rgba(color(lilac), 0.2);
      border: 0;
      color: color(purple);

      &:first-child {
        @include border-radius(0);
      }
    }

    &__status {
      &-active {
        color: color(enaro-blue);
      }
      &-inactive {
        color: color(red);
      }
    }

    &__text {
      @include font-size(small);
    }

    &__unit {
      @include font-size(large);
      @include font-weight(bold);
    }

    &__footer {
      background-color: color(white);

      &--title {
        color: color(grey);
      }
    }
  }
}
