.bucket {
  @include respond(medium) {
    padding: 5px;
  }

  @include border-radius(3px);
  padding: 15px;
  min-width: 23%;
  background-color: color(white);

  &__title {
    @include font-size(small);
    @include font-weight(regular);
    color: color(dark-indigo);
  }

  &__figure {
    @include font-weight(bold);
    @include font-size(medium);

    @include respond(medium) {
      @include font-size(regular);
      letter-spacing: 0.05rem;
    }
  }

  span {
    @include font-weight(light);
    @include font-size(small);
  }

  &__link {
    color: color(enaro-blue);
    cursor: pointer;
  }

  .meter {
    &-status {
      color: color(enaro-blue);
      float: right;

      &.active {
        color: color(enaro-blue);
      }
      &.inactive {
        color: color(red);
      }
    }

    &-options {
      cursor: pointer;

      &__caption {
        color: color(enaro-blue);
      }

      &__dropdown {
        @include font-size(small);
        @include box-shadow(1px, 2px, 10px, rgba(21, 21, 25, 0.055));
        position: absolute;
        padding: 3px 5px 5px 5px;
        margin-left: -10px;
        background-color: color(white);
        width: 200px;
        min-height: 100px;
        height: 150px;
        z-index: 2;
        overflow: hidden;
        overflow-y: scroll;

        &-header {
          @include font-size(small);
          padding: 10px;
          margin: 0;
          border-bottom: 1px solid color(grey);
        }

        &-items {
          cursor: pointer;
          display: block;
          padding: 10px;

          &:not(:last-child) {
            border-bottom: 1px solid color(grey);
          }
          &:hover {
            background-color: #f7f7f7;
          }
        }
      }
    }
  }
}
