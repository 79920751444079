body {
  @include respond(small) {
    @include font-size(small);
  }

  @include font-family(avenir);
  @include font-weight(regular);
  @include font-size(regular);
  line-height: normal;
  letter-spacing: normal;
  color: color(dark);

  position: relative;
}

.content {
  &__area {
    padding: 1.575rem;
  }

  &__text {
    padding: 0.5rem 0.905rem;
  }

  &__section-title {
    margin: 15px 0 5px 0;
  }

  &__list {
    margin: 5px 0;
    padding: 0 0 0 15px;
    li {
      margin: 20px 0 0 0;
    }
    // counter-reset: unset;
    ol {
      padding: 0 0 0 15px;
      li {
        margin: 5px;
      }
    }
  }
}

.noborder {
  &--bottom {
    border-bottom: 0 !important;
  }
  &--top {
    border-top: 0 !important;
  }
}

.underline {
  text-decoration: underline;
}
