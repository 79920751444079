.scar {
    max-width: 300px !important;
}

.bubble {
    &__status {
        @include font-size(x-small);
        @include border-radius(10px);
        padding: 3px 5px;
        border: 2px solid;
        text-transform: uppercase;
    }

    &--in-progress {
        color: color(indigo);
        border-color: color(indigo);
    }

    &--resolved,
    &--success {
        color: color(blue);
        border-color: color(blue);
    }

    &--pending {
        color: color(amber);
        border-color: color(amber);
    }

    &--approved {
        color: color(green);
        border-color: color(green);
    }

    &--closed,
    &--declined {
        color: color(red);
        border-color: color(red);
    }

    &--card {
        color: color(amber);
        border-color: color(amber);
    }
}
