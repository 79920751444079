.dashboard {
  &__subtitle {
    @include font-size(medium);
    @include font-weight(bold);
    color: color(dark);
  }

  a {
    @include font-size(regular);
    color: color(enaro-blue);
  }

  &-customer {
    .bucket {
      min-height: 132px;

      @include respond(small) {
        min-height: inherit;
      }
    }
  }
}
