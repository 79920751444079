.footer {
  padding: 1rem;
  background: color(light-grey);

  .copyright {
    @include font-size(small);

    &__link {
      color: color(black);
    }
  }

  &__title {
    color: color(dark);
  }
}
