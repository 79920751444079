.transactions {
  &__channel,
  &__status {
    padding: 3px 15px;
    @include border-radius(10px);
    border: 1px solid;
    text-transform: uppercase;
    &.-ussd {
      color: color(dark);
      border-color: color(dark);
    }
    &.-card {
      color: color(amber);
      border-color: color(amber);
    }
    &.-bank {
      color: color(blue);
      border-color: color(blue);
    }
  }

  &__status {
    &.-success {
      color: color(skyblue);
      border-color: color(skyblue);
    }
    &.-failed {
      color: color(red);
      border-color: color(red);
    }
  }
}
