.teams {
  &__header {
    padding: 0.8rem 0 !important;
  }

  .accordion {
    margin-top: 2.5rem;

    .card {
      @include border-radius(5px);
      background-color: transparent;
      border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    }
  }

  &__switch {
    .nav {
      &-item:first-of-type {
        .nav-link {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }
      }
      &-item:last-of-type {
        .nav-link {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
        }
      }

      &-item {
        margin: 0;

        .active {
          background-color: color(white) !important;
          color: inherit !important;

          &:hover {
            color: inherit !important;
          }
        }
      }

      &-link {
        color: color(white) !important;
        border: 1px solid color(dark);
        background-color: color(dark);

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
