.switcher {
  @include font-size(x-small);

  &__title {
    @include font-size(small);
    color: color(enaro-blue) !important;
    margin-bottom: 4px;
  }

  &__control {
    @include border-radius(0 !important);
    min-height: 25px !important;

    &--is-focused {
      border-color: color(indigo) !important;
    }
  }

  &__option {
    &--is-focused {
      background-color: color(indigo) !important;
      color: color(white) !important;
    }

    &--is-selected {
      background-color: color(indigo) !important;
      color: color(white) !important;
    }
  }

  &__menu {
    @include border-radius(0 !important);
  }

  &__indicator {
    padding: 1px !important;
  }

  &__menu {
    @include box-shadow(1px, 2px, 10px, rgba(21, 21, 25, 0.055));
    margin-top: 8px;
    position: absolute;
    z-index: 2;
    width: 100%;
  }

  &__blanket {
    bottom: 0;
    left: 0;
    top: 0;
    right: 0;
    position: 'fixed';
    z-index: 1;
  }

  &__dropdown {
    position: relative;
    width: 100%;
    // background-color: color(lilac);

    button {
      width: 100%;
    }
  }
}
