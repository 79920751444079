.wrapper {
  position: relative;
  top: 0;
  height: 100vh;

  .menuToggle {
    @include box-shadow(1px, 2px, 10px, rgba(21, 21, 25, 0.055));
    position: relative;
    top: 0;
    z-index: 2;
    color: #000000;
    display: none;
    border: 1px solid #eee;

    @include respond(small) {
      display: block;
    }

    .logo {
      @include respond(small) {
        display: block;
      }
      // width: 30%;
    }

    img {
      object-fit: cover;
    }
  }

  .logo {
    @include respond(small) {
      display: none;
    }
  }
}

.main-panel {
  background: color(main-bg);
  position: relative;
  float: right;
  width: calc(100% - 260px);
  min-height: 100%;

  @include respond(small) {
    width: 100%;
  }

  .navbar {
    margin-bottom: 0;
  }

  .section {
    padding: 0;
  }

  .content {
    min-height: calc(100vh - 60px);
  }

  .footer {
    border-top: 1px solid #e7e7e7;
  }
}

.main-panel {
  overflow: auto;
  max-height: 100%;
  height: 100%;
  -webkit-transition-property: top, bottom;
  transition-property: top, bottom;
  -webkit-transition-duration: 0.2s, 0.2s;
  transition-duration: 0.2s, 0.2s;
  -webkit-transition-timing-function: linear, linear;
  transition-timing-function: linear, linear;
  -webkit-overflow-scrolling: touch;

  @include respond(small) {
    float: none;
    width: 100%;
    -webkit-transform: translate3d(0px, 0, 0);
    -moz-transform: translate3d(0px, 0, 0);
    -o-transform: translate3d(0px, 0, 0);
    -ms-transform: translate3d(0px, 0, 0);
    transform: translate3d(0px, 0, 0) !important;
    -webkit-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -moz-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -o-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -ms-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  }
}
