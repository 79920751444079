.ticket {
  &__container {
    @include border-radius(10px);
    background-color: color(white);
  }

  &__content {
    // border-right: 2px solid color-not-opaque(grey);
    height: 100%;
  }

  &__listing {
    &--title {
      padding: 50px 0 20px 30px;
    }

    &--content {
      padding: 0 30px 20px;
    }
  }

  &__custom {
    padding-top: 1.675rem;
  }

  &__details {
    padding-top: 50px;
  }

  &--details {
    overflow-wrap: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;

    div.header {
      @include font-weight(bold);
    }
  }

  &__source {
    &--offline {
      text-transform: capitalize;
      color: color(red);
    }

    &--online {
      text-transform: capitalize;
      color: color(green);
    }
  }
}

.tickets {
  .dropdown-menu {
    padding: 1px;
  }
  .dropdown-item {
    @include font-size(x-small);
    text-transform: uppercase;
    padding: 0.55rem 1rem;
  }

  .displayDots {
    display: block;
  }
  .hideDots {
    display: none;
  }
}
