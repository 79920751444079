.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;

  overflow: auto;
  max-height: 100%;
  height: 100%;
  width: 260px;

  display: block;
  z-index: 1;

  -webkit-transition-property: top, bottom;
  transition-property: top, bottom;
  -webkit-transition-duration: 0.2s, 0.2s;
  transition-duration: 0.2s, 0.2s;
  -webkit-transition-timing-function: linear, linear;
  transition-timing-function: linear, linear;
  -webkit-overflow-scrolling: touch;

  @include respond(small) {
    right: 0 !important;
    left: auto;
    position: absolute;
    display: none;
  }

  .nav {
    &-link {
      @include font-size(regular);
      @include font-weight(regular);
      line-height: 2.2;
      color: color(grey) !important;
      padding: 15px 25px;

      &.active {
        color: color(off-white) !important;
        background-color: color-not-opaque(grey);
        border-left: 4px solid color(primary);
      }

      i {
        @include font-size(small);
        margin-right: 15px;
        width: 30px;
      }

      &:hover {
        background-color: color-not-opaque(grey);
        border-left: 4px solid color(primary);
      }
    }

    li,
    a {
      -webkit-transition: all 150ms ease-in;
      -moz-transition: all 150ms ease-in;
      -o-transition: all 150ms ease-in;
      -ms-transition: all 150ms ease-in;
      transition: all 150ms ease-in;
    }

    .active-pro {
      position: absolute;
      width: 100%;
      bottom: 10px;
    }
  }

  &-wrapper {
    position: relative;
    max-height: calc(100vh - 75px);
    min-height: 100%;
    overflow: auto;
    width: 260px;
    z-index: 4;
    padding-bottom: 100px;
  }

  .logo {
    padding: 20px 15px 80px;
    position: relative;
    z-index: 4;
  }

  &-reveal {
    position: absoulte;
    top: 0;
    left: 0;

    overflow: auto;
    width: 100%;

    display: block;
    z-index: 1;

    background: color(dark);

    .logo {
      padding: 20px 15px 80px;
      position: relative;
      z-index: 4;
    }

    .nav {
      &-link {
        @include font-size(regular);
        @include font-weight(regular);
        line-height: 2.2;
        color: color(grey) !important;
        padding: 15px 25px;

        &.active {
          color: color(off-white) !important;
          background-color: color-not-opaque(grey);
          border-left: 4px solid color(primary);
        }

        i {
          @include font-size(small);
          margin-right: 15px;
          width: 30px;
        }

        &:hover {
          background-color: color-not-opaque(grey);
          border-left: 4px solid color(primary);
        }
      }

      li,
      a {
        -webkit-transition: all 150ms ease-in;
        -moz-transition: all 150ms ease-in;
        -o-transition: all 150ms ease-in;
        -ms-transition: all 150ms ease-in;
        transition: all 150ms ease-in;
      }

      .active-pro {
        position: absolute;
        width: 100%;
        bottom: 10px;
      }
    }
  }
}

.sidebar:after,
.sidebar:before {
  display: block;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}

.sidebar:before {
  opacity: 0.33;
  background: #000000;
}

.sidebar:after {
  background: color(dark);
  z-index: 3;
  opacity: 1;
}
