.content {
  &__header {
    margin: 1.5rem 0.75rem;
    position: relative;

    .navbar {
      padding: 0;
    }

    .nav-link {
      padding: 0;
    }
  }
  &__title {
    margin: 1.5rem 0.75rem;
  }
}
