.no-content {
  padding-top: 3rem;

  &__title {
    @include font-size(medium);
    @include font-weight(semi-bold);
    color: color(dark);
    margin: 0;
  }

  &__text {
    @include font-size(regular);
    @include font-weight(light);
  }

  &__content {
    @include border-radius(10px);
    @include box-shadow(0px, 0px, 20px, rgba(21, 21, 25, 0.055));

    background-color: color(white);
    padding: 1rem 2rem;
    padding-bottom: 4rem;
    margin-top: 2rem;

    h3 {
      @include font-size(regular);
      @include font-weight(semi-bold);
    }

    p {
      @include font-size(small);
      @include font-weight(light);
    }

    &-icon {
      @include border-radius(100px);
      width: 100px;
      height: 100px;
      margin: 20px auto;
      background-color: color-not-opaque(lilac);

      i {
        @include font-size(m-large);
        color: color(indigo);
        width: 50px;
        height: 35px;
      }
    }
  }
}
