.section {
  padding: 3rem 0;

  &__content {
    @extend %border-radius-5;
    border: 1px solid #dfe0eb;
    margin: 2rem;
    background-color: white;
  }
}
