.icon {
  &-edit::before {
    content: '\e900';
  }

  &-notif::before {
    content: '\e901';
  }

  &-payment::before {
    content: '\e902';
  }

  &-setting::before {
    content: '\e903';
  }

  &-ticket::before {
    content: '\e905';
  }

  &-dots::before {
    content: '\e906';
  }

  &-reveal::before {
    content: '\e907';
  }

  &-add::before {
    content: '\e908';
  }

  &-trash::before {
    content: '\e909';
  }

  &-estate::before {
    content: '\e910';
  }

  &-back-arrow::before {
    content: '\e911';
  }

  &-edit::before {
    content: '\e900';
  }

  &-caution::before {
    content: '\e90a';
  }

  &-eyelash::before {
    content: '\e90c';
  }

  &-user::before {
    content: '\e90d';
  }

  &-pie::before {
    content: '\e90f';
  }

  &-team::before {
    content: '\e914';
  }

  &-log::before {
    content: '\e915';
  }

  &-logout::before {
    content: '\ea14';
  }

  &-filter::before {
    content: '\e90b';
  }

  &-export::before {
    content: '\e913';
  }

  &-blue-dot::before {
    content: '\e90e';
  }

  &-big-purple-dot::before {
    content: '\e916';
  }

  &-calendar::before {
    content: '\e912';
  }

  &-no-estate::before {
    content: '\e917';
  }

  &-no-payment::before {
    content: '\e918';
  }

  &-no-ticket::before {
    content: '\e919';
  }

  &-realtime::before {
    content: '\e91a';
  }

  &-utility::before {
    content: '\e91c';
  }

  &-angle-right::before {
    content: '\f104';
  }

  &-angle-left::before {
    content: '\f105';
  }

  &-download::before {
    content: '\e960';
  }

  &-import::before {
    content: '\e961';
  }
}

.iconmoon {
  text-transform: none;
  line-height: 1;
  font-family: 'icomoon' !important;
  font-weight: normal;
  font-variant: normal;
  font-style: normal;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
