.residents {
  &__estate {
    @include border-radius(10px);
    background-color: color(white);

    &--details {
      @include font-size(small);
    }

    .header {
      color: color(purple);
    }
  }

  &__buckets {
    &--card {
      background-color: color(white);
      padding: 5px 10px;
      margin-bottom: 10px;
      border: 1px solid #eee;
      border-radius: 5px;
      display: inline-block;
      width: 100%;
      color: color(enaro-indigo);
      overflow-wrap: break-word;
      -webkit-hyphens: auto;
      -moz-hyphens: auto;
      -ms-hyphens: auto;
      hyphens: auto;
    }

    &--detail {
      color: color(dark) !important;
    }
  }
}
