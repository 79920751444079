.settings {
}

.sub {
  &-heading {
    @include respond(small) {
      @include font-size(small);
    }

    @include font-family(avenir);
    @include font-weight(regular);
    @include font-size(regular);
    color: color(dark-indigo);
  }

  &-paragraph {
    @include respond(small) {
      @include font-size(x-small);
    }

    @include font-family(avenir);
    @include font-weight(regular);
    @include font-size(small);
  }
}

.profile {
  &__row {
    border-bottom: 1px solid;
    border-color: color-not-opaque(grey);
    padding: 1rem 0;

    &:last-child {
      border: none;
    }
  }
}

.field {
  &__relative {
    position: relative;
  }
  &__absolute-tr {
    position: absolute;
    top: 0;
    right: 0;
  }

  &__absolute-spinner {
    position: absolute;
    top: 12px;
    right: 7px;
  }
}
