.PhoneInput {
    border: 1px solid #b7ccdc;
    padding: 1.2rem 0;
    height: calc(1.5em + 0.75rem + 2px);
    border-radius: 0.25rem;

    :focus {
        background-color: #fff;
        border-color: #86b7fe;
        outline: 0;
    }

    .PhoneInputCountry {
        height: 100%;
        padding: 0 0.5rem;

    }

    .PhoneInputInput {
        height: 40.4px;
        display: block;
        width: 300px;
        background: transparent;
        border: none;
        padding: 0 7px;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
}
