.nav {
  &-tabs {
    border: 0;
    background-color: color(white);
    padding-left: 1rem;

    .nav-item {
      margin-bottom: 0;

      .active {
        border: 0px;
        background-color: color(white);
        border-bottom: 3px solid color(primary);

        &:hover {
          border-bottom: 3px solid color(primary);
        }
      }
    }

    .nav-link {
      border: 0;
      padding: 0.8rem 0.1rem;
    }
  }

  &-pills {
    .nav-link {
      border-radius: 0;

      &:hover {
        border: 0;
        color: color(primary) !important;
        cursor: pointer;
      }
    }
  }
}
