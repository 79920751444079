.modal {
  &-view {
    &__header {
      background-color: color(lilac);
      padding: 0.3rem 2rem;

      .header-title {
        @include font-size(medium);
        @include font-weight(semi-bold);
        color: color(dark);
        margin: 0;
      }

      .header-text {
        @include font-size(regular);
        @include font-weight(light);
      }

      &--details {
        color: red;
      }
    }

    &__close {
      @include border-radius(25px);
      position: absolute;
      top: 20px;
      right: 20px;
      border: 1px solid color(dark);
      width: 25px;
      height: 25px;

      &:hover {
        border: 1px solid color(dark);
      }
    }

    &__absolute {
      position: absolute;
      right: 20px;
    }

    &__content {
      padding: 1rem 2rem;
    }
  }
}
