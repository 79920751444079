.heading {
  @include respond(small) {
    @include font-size(regular);
  }

  @include font-family(avenir);
  @include font-weight(bold);
  @include font-size(large);
  line-height: 1.8;
  letter-spacing: 0.2px;
  letter-spacing: normal;
  color: color(dark);
}

.medium-heading {
  @include respond(small) {
    @include font-size(small);
  }

  @include font-family(avenir);
  @include font-weight(semi-bold);
  @include font-size(medium);
  line-height: 1.8;
  letter-spacing: 0.2px;
  letter-spacing: normal;
  color: color(dark);
}

.section-heading {
  @include respond(small) {
    @include font-size(regular);
  }

  @include font-family(avenir);
  @include font-weight(light);
  @include font-size(medium);
  line-height: 1.8;
  letter-spacing: 0.2px;
  letter-spacing: normal;
  color: color(dark);
}

.paragraph {
  @include respond(small) {
    @include font-size(small);
  }

  @include font-family(avenir);
  @include font-weight(regular);
  @include font-size(regular);
  line-height: 1.8;
  letter-spacing: 0.2px;
  letter-spacing: normal;
  color: color(dark);
}

.border-fill {
  @include border-radius(30px);
  border: 1px solid color(dark);
}

.icon-2x {
  @include font-size(regular);
}
