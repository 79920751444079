.auth {
    &__container {
        background: url('../../images/auth-background.png') no-repeat;
        background-size: cover;
        height: 100%;
        display: flex;
        align-items: center;
        position: relative;
    }

    &__box {
        width: 100%;
        position: relative;
        z-index: 1;
    }

    &__content {
        @include border-radius(10px);
        box-shadow: 0 5px 20px rgba(21, 21, 25, 0.15);
        padding: 1rem 2rem;
        padding-top: 1.3rem;
        padding-bottom: 2.7rem;
        background-color: color(white);
    }

    &__logo-container {
        width: 100%;
        margin-bottom: 1.6rem;
    }

    &__link {
        @include font-size(small);
        color: color(enaro-blue);
        margin-top: 5px;
    }

    &__password {
        position: relative;

        &--reveal {
            position: absolute;
            top: 55%;
            right: 25px;
            z-index: 2;
            cursor: pointer;
        }

        &--error {
            top: 45%;
        }
    }

    form:before {
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.3);
        z-index: -1;
        border-radius: 10px;
    }
}
