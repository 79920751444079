.pagination {
  @include font-size(small);

  .page-link {
    line-height: 1.35;
    padding: 0.25rem 0.55rem;
  }

  &__controls {
    &-selectbox {
      display: flex;
      flex-direction: row;

      label {
        @include font-size(small);
        width: 100%;
      }

      select {
        @include font-size(small);
        height: calc(1.5em + 0.75rem + 2px);
        margin-top: -2px;
        color: #495057;
        background-color: transparent;
        border: 0px;
      }
    }

    &-navigation {
      cursor: pointer;
      padding: 0 5px;
    }
  }
}
