// Muli
@font-face {
  font-family: 'Avenir';
  font-weight: 100;
  font-style: normal;
  src: url('../../fonts/Avenir/Avenir-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Avenir';
  font-weight: 400;
  font-style: normal;
  src: url('../../fonts/Avenir/Avenir-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Avenir';
  font-weight: 600;
  font-style: normal;
  src: url('../../fonts/Avenir/Avenir-Book.ttf') format('truetype');
}

@font-face {
  font-family: 'Avenir';
  font-weight: 700;
  font-style: normal;
  src: url('../../fonts/Avenir/Avenir-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-weight: 100;
  font-style: normal;
  src: url('../../fonts/ibm-plex-sans/IBMPlexSans-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-weight: 400;
  font-style: normal;
  src: url('../../fonts/ibm-plex-sans/IBMPlexSans-Regular.ttf')
    format('truetype');
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-weight: 600;
  font-style: normal;
  src: url('../../fonts/ibm-plex-sans/IBMPlexSans-SemiBold.ttf')
    format('truetype');
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-weight: 700;
  font-style: normal;
  src: url('../../fonts/ibm-plex-sans/IBMPlexSans-Bold.ttf') format('truetype');
}

// iconmoon
@font-face {
  font-family: 'icomoon';
  font-weight: normal;
  font-style: normal;
  src: url('../../fonts/icomoon/icomoon.eot?ukh0o3#iefix')
      format('embedded-opentype'),
    url('../../fonts/icomoon/icomoon.ttf?ukh0o3') format('truetype'),
    url('../../fonts/icomoon/icomoon.woff?ukh0o3') format('woff'),
    url('../../fonts/icomoon/icomoon.svg?ukh0o3#icomoon') format('svg');
  font-display: block;
}
