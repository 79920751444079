.button {
  @include respond(smaller) {
    @include font-size(x-small);
  }
  @include font-size(small);
  @include font-weight(light);
  @include border-radius(3px);
  display: inline-block;
  border: 0;
  padding: 0.775rem 1.75rem;
  vertical-align: middle;
  text-align: center;
  text-transform: uppercase;
  line-height: 1.5;
  transition: transform 0.3s;

  &:hover {
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
    color: initial !important;
    transform: translateY(-1px);
    -webkit-transform: translateY(-1px);
  }

  &-primary {
    color: color(white);
    background-color: color(enaro-blue);
    border-color: color(enaro-blue);
    &:hover {
      color: color(white) !important;
    }

    &__outline {
      border: 1px solid color(enaro-blue);
      background: transparent;
      color: color(enaro-blue);

      &:hover {
        color: color(enaro-blue) !important;
      }
    }
  }

  &-info {
    color: color(white);
    background-color: color(enaro-blue);
    border-color: color(enaro-blue);
    &:hover {
      color: color(white);
    }
  }

  &-secondary {
    color: color(white);
    background-color: color(dark);
    border-color: color(dark);
    &:hover {
      color: color(white) !important;
    }
  }

  &-lowcase {
    @include respond(smaller) {
      @include font-size(small);
    }
    @include font-size(small);
    text-transform: none;

    span {
      margin-left: 10px;
    }
  }

  &-small {
    @include font-size(small);
    padding: 0.25rem 0.5rem;
    line-height: 1.5;
    border-radius: 0.2rem;
  }

  &-save {
    color: color(white);
    background-color: color(purple);
    border-color: color(purple);

    &:hover {
      color: color(white) !important;
    }
  }

  &-transparent {
    @include font-weight(regular);
    color: color(dark);
    background-color: transparent;
    border-color: transparent;

    &:hover {
      color: color(dark);
      border-color: transparent;
      background-color: transparent;
      box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    }

    &:active {
      color: color(dark);
      border-color: transparent !important;
      background-color: transparent !important;
      box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    }

    &:focus {
      // color: color(dark);
      // border-color: transparent !important;
      // background-color: transparent !important;
      box-shadow: 0 0 0 rgba(0, 0, 0, 0) !important;
    }
  }

  &-link {
    color: color(grey);
    &:hover {
      color: color(grey);
    }
  }

  &-icon {
    @include font-size(regular);
    padding: 0;

    &:hover {
      @include font-weight(regular);
    }
  }

  &-switcher {
    &::after {
      display: inline-block;
      margin-left: 0.255em;
      vertical-align: 0.255em;
      content: '';
      border-top: 0.3em solid;
      border-right: 0.3em solid transparent;
      border-bottom: 0;
      border-left: 0.3em solid transparent;
      right: 0;
    }
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &.disabled {
    opacity: 0.3;
  }

  &-caps {
    @include font-size(x-small);
    text-transform: uppercase;
  }

  &-nopadding {
    padding: 0 !important;
  }

  &-controls {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: felx-start;

    @include respond(medium) {
      margin-top: 20px;
    }
  }
}

.show {
  .dropdown-toggle {
    color: inherit !important;
    background-color: transparent !important;
    border-color: transparent !important;

    &:focus {
      border-color: transparent !important;
    }
  }
}

.dropdown {
  &-item {
    @include respond(smaller) {
      @include font-size(x-small);
    }
    @include font-size(small);
    border: 0;
    text-align: left;

    &:active {
      color: inherit !important;
      background-color: transparent !important;
      border-color: transparent !important;
    }

    &:focus {
      outline: 0px;
      border-color: transparent !important;
    }
  }

  &-menubox {
    min-width: 6rem;
  }
}
