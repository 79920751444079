.import,
.edit-control,
.delete-control,
.create-control {
  input {
    visibility: hidden;
    display: none;
  }

  label {
    @include font-size(small);
    cursor: pointer;
    text-align: center;
  }
}

.edit-control,
.delete-control {
  label {
    width: 50px;
  }
}

.create-control {
  label {
    width: 100%;
    text-align: left;
    margin-top: 0;
  }
}

.controls {
  margin-right: 25px;
}

.no-controls {
  display: none;
  visibility: hidden;

  label {
    color: color(gray);
  }
}

.import-control {
  cursor: pointer;
}

.units {
  .dropdown {
    &-toggle {
      @include font-size(regular);
      width: 100%;
      text-align: left;
      text-transform: capitalize;
      padding: 0;

      &:focus {
        @include box-shadow(0px, 0px, 0px, rgba(255, 255, 255, 0));
      }
    }

    &-item {
      &:hover,
      &:focus {
        color: color(white);
        background-color: color(enaro-blue);
      }
    }
  }

  &-listing {
    max-height: 200px;
    min-height: 200px;
    overflow-y: scroll;
    text-align: center;

    button {
      margin: auto 0;
    }
  }

  &__button-add {
    width: initial !important;
    background-color: #008fff;
  }
}
