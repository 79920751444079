.overview {
  &__summary {
    background-color: color(white);
    padding: 5px 10px;
    border: 1px solid #eee;
    border-radius: 5px;
    letter-spacing: 1px;
    &:not(:last-child) {
      margin-right: 2.5%;
    }
  }

  .total-revenue {
    padding: 10px;
    background-color: color(white);
  }
  .total-revenue__amount {
    font-weight: bold;
  }
  .total-revenue__log {
    color: color(blue);
    cursor: pointer;
    border-bottom: 1px solid color(blue);
    &:hover {
      color: color(enaro-indigo);
      border-bottom: 1px solid color(enaro-indigo);
    }
  }

  &__estate {
    &-listings {
      width: 100%;
      border-radius: 5px;
      border: 1px solid #eee;
      background: color(white);

      &--heading {
        background: color(lilac);
        padding: 20px;
      }
      &--body {
        padding: 20px;
      }
    }

    &--card {
      @include border-radius(3px);
      @include box-shadow(1px, 2px, 10px, rgba(21, 21, 25, 0.055));
      border: 1px solid #eee;
      margin-bottom: 20px;
      background: color(white);

      &-title {
        @include font-size(regular);
        @include font-weight(regular);

        color: color(enaro-indigo);
        text-transform: capitalize;
        padding: 0.9375rem;
        border-bottom: 1px solid #eee;
      }

      &-details {
        padding: 0.9375rem;
      }

      &-subtitle {
        @include font-size(regular);
        @include font-weight(light);
        color: color(enaro-blue);
      }

      &-street {
        width: 100%;
        display: block;
      }
    }
  }
}
