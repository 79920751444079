.form,
.fields {
  label {
    @include font-size(small);
    color: color(enaro-grey);
    margin-bottom: 0.5rem;
  }

  select,
  textarea,
  input[type='password'],
  input[type='text'],
  input[type='number'],
  input[type='email'] {
    @include font-size(regular);
    @include font-family(avenir);
    color: color(enaro-grey);
  }

  input[type='password'],
  input[type='text'],
  input[type='number'],
  input[type='email'] {
    padding: 1.2rem 1rem;
    height: calc(1.5em + 0.75rem + 2px);
    border-radius: 0.25rem;
  }

  select {
    height: 40px;
  }

  &-title {
    padding-bottom: 34px;
  }

  button {
    @include font-size(x-small);
    text-transform: uppercase;
    // margin-top: 20px;

    &:disabled {
      opacity: 0.45;
    }
  }

  &-check {
    &-label {
      color: color(grey) !important;
    }
  }

  &-control {
    border: 1px solid color(blue-grey);
  }

  &-group {
    position: relative;
  }

  &-error {
    @include font-size(small);
    color: color(red);
    margin-top: 3px;

    span {
      margin-left: 5px;
    }

    i {
      margin-right: 5px;
    }
  }

  &-border-red {
    border: 1px solid color(red) !important;
  }

  ::placeholder {
    /* Use rgba instead */
    color: color(enaro-grey);
    opacity: 0.3;
  }

  &__header {
    background-color: color(lilac);
    padding: 0.3rem 2rem;

    .header-title {
      @include font-size(medium);
      @include font-weight(semi-bold);
      color: color(dark);
      margin: 0;
    }

    .header-text {
      @include font-size(small);
      @include font-weight(light);
    }
  }

  &__content {
    padding: 1rem 2rem;
  }

  &__link {
    @include font-size(small);
    color: color(indigo);
  }

  &__footer {
    border-top: 1px solid #e7e7e7;
    padding: 1rem 2rem;

    &-buttons {
    }
  }
}
