.comment {
  @include border-radius(5px);
  background-color: rgba(color(grey), 0.3);
  padding: 0.85rem;
  margin-bottom: 0.8rem;
  overflow-wrap: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;

  &-header {
    color: color(purple);
  }
}
