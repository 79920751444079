.location {
  &__container {
    @include border-radius(10px);
    background-color: color(white);
  }

  &__content {
    border-right: 2px solid color-not-opaque(grey);
    height: 100%;
  }

  &__listing {
    &--title {
      padding: 50px 0 20px 30px;
    }

    &--content {
      padding: 0 30px 20px;
    }
  }

  &__custom {
    padding-top: 1.675rem;
  }

  &__details {
    padding-top: 50px;

    @include respond(small) {
      h6 {
        padding: 0 1.25rem;
      }
    }
  }

  &__city-details {
    color: color(gray);
    padding-top: 15px;
    margin-right: 30px;

    @include respond(small) {
      padding-right: 1.25rem;
      padding-left: 1.25rem;
      margin-right: 5px;
    }

    .list-group-flush {
      width: 50%;

      @include respond(small) {
        width: 100%;
      }
    }
  }

  .nav {
    &-item {
      border-bottom: 1px solid color-not-opaque(grey);
      margin: 0;
    }

    &-link {
      padding-top: 0.925rem;
      padding-bottom: 0.725rem;
      padding-left: 1.875rem;
    }

    .active {
      color: color(blue) !important;
      background-color: color(light-blue);
    }
  }

  &__permissions {
    .list-group-item:first-child {
      border-radius: 0px;
    }
    .list-group-item:last-child {
      border-radius: 0px;
    }
    &--header {
      background-color: color(light-blue);
      border: none;
    }
  }
}
