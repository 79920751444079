$amber: #ffc06a;
$black: #000;
$blue: #3b86ff;
$blue-grey: #b7ccdc;
$dark: #0d2d4d;
$dark-indigo: #d53de3;
$enaro-blue: #008fff;
$enaro-indigo: #ff2ddd;
$enaro-grey: #677996;
$grey: #a4a6b3;
$indigo: #dd3ae2;
$light-blue: #d8e7ff;
$light-grey: #f8f9fa;
$lilac: #f3c5ff;
$primary: #cb41e4;
$purple: #be46e6;
$off-white: #dde2ff;
$red: #de1717;
$skyblue: #1992fb;
$white: #fff;
$green: #428c00;
$main-bg: #f7f8fc;

$colors: (
  amber: #ffc06a,
  black: #000,
  blue: #3b86ff,
  blue-grey: #b7ccdc,
  dark: #0d2d4d,
  dark-indigo: #d53de3,
  enaro-blue: #008fff,
  enaro-indigo: #ff2ddd,
  enaro-grey: #677996,
  grey: #a4a6b3,
  indigo: #dd3ae2,
  light-blue: #d8e7ff,
  light-grey: #f8f9fa,
  lilac: #f3c5ff,
  primary: #cb41e4,
  purple: #be46e6,
  off-white: #dde2ff,
  red: #de1717,
  skyblue: #1992fb,
  white: #fff,
  green: #428c00,
  main-bg: #f7f8fc
);

$rgbas: (
  grey: rgba(159, 162, 180, 0.2),
  lilac: rgba(187, 71, 230, 0.1),
  // rgba(0, 0, 0, 0.125)
);

$font-weights: (
  light: 300,
  regular: 400,
  semi-bold: 600,
  bold: 700
);

$font-families: (
  ibm: 'IBM Plex Sans',
  avenir: 'Avenir',
  icomoon: 'icomoon'
);

$font-sizes: (
  xx-small: 8px,
  x-small: 10px,
  small: 12px,
  regular: 14px,
  medium: 20px,
  large: 25px,
  m-large: 30px,
  x-large: 40px,
  xx-large: 70px
);

%border-radius {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}

%border-radius-5 {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
